<template>
  <v-layout justify-center class="dashboard-container">
    <v-flex xs12 sm12 md10>
      <material-card
        title="Companies List/Lista de Compañias"
        icon="mdi-office-building"
      >
        <v-card-text class="page-info dashboard-info">
          <v-btn color="primary" dark class="mb-2" to="company/new"
            >New/Nuevo</v-btn
          >
          <v-data-table
            :headers="headers"
            :items="companiesLists"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
            :loading="isLoading"
          >
            <v-progress-linear
              slot="progress"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.name }}</td>
              <td style="text-align: center">
                <input type="checkbox" v-model="props.item.active" disabled />
              </td>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }"
              class="justify-center layout px-0"
            >
              <v-icon
                small
                class="mr-2"
                @click="edit(item)"
                title="Edit/Editar"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
          <template slot="pageText" slot-scope="props">
            Page {{ pagination.page }} - {{ pages }}, Found
            {{ pagination.totalItems }}
          </template>
        </v-card-text>
      </material-card>
    </v-flex>
    <v-dialog v-model="showBan" persistent max-width="400">
      <v-card>
        <v-card-title class="headline" v-if="banCompany.active"
          >Block company?/¿Bloquear compañia?</v-card-title
        >
        <v-card-title class="headline" v-if="!banCompany.active"
          >Active company?/¿Activar compañia?</v-card-title
        >
        <v-card-text>{{ banCompany.id + " / " + banCompany.name }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action" color="blue" v-on:click.prevent="block"
            >Agree/Acepto
            <v-icon right>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn @click="showBan = false" color="red"
            >No
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import companies from "../../services/api/companies";

export default {
  name: "companiesLists",
  components: {
    MaterialCard: () => import("@/components/base/MaterialCard"),
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: "Name/Nombre",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Enabled/Activo",
          align: "center",
          sortable: false,
          value: "active",
        },
        {
          text: "Actions/Acciones",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      pagination: {
        bActivePaged: true,
        page: 1,
        itemsPerPage: 15,
        sortBy: "",
        sortDesc: false,
      },
      options: {},
      totalItems: 0,
      companiesLists: [],
      showBan: false,
      banCompany: {},
    };
  },
  async created() {},
  watch: {
    options: {
      handler() {
        this.getAllCompaniesPaged();
      },
      deep: true,
    },
    asc(newVal) {
      if (this.desc && newVal) this.desc = false;
    },

    desc(newVal) {
      if (this.asc && newVal) this.asc = false;
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getAllCompaniesPaged() {
      this.setLoading(true);
      try {
        this.pagination.filters = "";
        this.pagination.bActivePaged = true;
        this.pagination.page = this.options.page;
        this.pagination.itemsPerPage = this.options.itemsPerPage;
        this.pagination.sortBy = this.options.sortBy[0];
        this.pagination.sortDesc = this.options.sortDesc[0];

        let OData = Object.assign({}, this.pagination);
        OData.qaFormat = JSON.stringify(this.pagination.qaFormat);

        const response = await companies.getAllCompaniesPaged(OData);
        var data = response.body.data;
        this.companiesLists = data;
        this.totalItems = response.body.totalItems;
      } catch (e) {
        this.$toast.error(e.body.message);
      }
      this.setLoading(false);
    },
    edit(company) {
      this.$router.push({
        name: "companyedit",
        params: {
          idCompany: company.idCompany,
        },
      });
    },
    ban(company) {
      this.showBan = true;
      this.banCompany = company;
    },
    async block() {
      await companies.block({
        idCompany: this.banCompany.idCompany,
        active: !this.banCompany.active,
      });
      this.showBan = false;
      this.getData();
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    pages() {
      if (
        this.pagination.itemsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.itemsPerPage
      );
    },
  },
};
</script>

<style>
</style>
